<template>
	<v-card flat class="bonus-table" :class="computedClass">
		<QDataTableHeader class="bonus-table__header">
			<template #left>
				<p class="bonus-table__header-summary">
					{{ title }} Bonus:
					<span v-if="!dataLoadingStatus.loading && !dataLoadingStatus.error">
						<span v-if="totalBonus < 0">-</span>
						<span class="with-currency">{{ formatNumber(Math.abs(totalBonus), '(0,0.00)') }}</span>
					</span>
				</p>
			</template>
			<template #right>
				<v-text-field
					v-model="search"
					:disabled="dataLoadingStatus.loading"
					append-icon="icon-q-search"
					label="Search"
					dense single-line hide-details
					class="bonus-table__header-search"
				/>
			</template>
		</QDataTableHeader>

		<v-row>
			<v-col class="d-flex justify-center justify-sm-space-between align-center flex-wrap">
				<span class="mx-4 my-1">{{ formattedDatePeriod.kebab }}</span>
				<v-btn
					v-if="dataLoadingStatus.error"
					class="error-btn"
					elevation="0"
					color="#1383EA"
					@click="loadData"
				>
					<v-icon>fas fa-sync-alt</v-icon>
					<v-divider class="mx-2" color="white" vertical />
					Reload Data
				</v-btn>

				<v-btn
					v-else
					text
					color="#1383EB"
					:disabled="dataLoadingStatus.loading || !items?.length"
					@click="exportResults"
				>
					<v-icon>fas fa-file-export</v-icon>
					<v-divider class="mx-2" vertical />
					Export Results
				</v-btn>
			</v-col>
		</v-row>

		<QExpandableDataTable
			:key="tableKey"
			:headers="visibleHeaders"
			:items="items"
			:options.sync="options"
			:expanded.sync="expanded"
			:search="search"
			item-key="ID"
			:loading="dataLoadingStatus.loading"
			class="bonus-table__table"
			color="q_leads_1"
			:dense="!isMobileTableView"
			:footer-props="{ itemsPerPageOptions: [10, 25, 50, 100] }"
			:show-expand="showExpand"
		>
			<template v-for="h in visibleHeaders" v-slot:[`item.${h.value}`]="{ item, value, index }">
				<div :key="`${h.value}-${index}`" :class="[h.cellClass, !isMobileView && 'nowrap']">
					<slot :name="`item.${h.value}`" v-bind="{ item, value, header: h }">
						{{ formatItem(value, h.value) }}
					</slot>
				</div>
			</template>
			<template v-if="showExpand" v-slot:expand="{ item }">
				<div class="bonus-table__expand-wrapper">
					<v-simple-table v-if="isMobileTableView">
						<tbody>
							<tr v-for="header in expandedHeaders" :key="header.value">
								<td class="text-start cell-title">
									{{ header.text }}
								</td>
								<td class="text-end">
									<slot :name="`item.${header.value}`" v-bind="{ item, value: item[header.value], header }">
										{{ formatItem(item[header.value], header.value) }}
									</slot>
								</td>
							</tr>
						</tbody>
					</v-simple-table>
					<template v-else>
						<v-simple-table v-if="expandedTable" dense class="bonus-table__line-table">
							<thead>
								<tr>
									<th v-for="h in expandedHeaders" :key="h.value">
										<span>{{ h.text }}</span>
									</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td v-for="h in expandedHeaders" :key="h.value">
										<span class="nowrap">
											<slot :name="`item.${h.value}`" v-bind="{ item, value: item[h.value], header: h }">
												{{ formatItem(item[h.value], h.value) }}
											</slot>
										</span>
									</td>
								</tr>
							</tbody>
						</v-simple-table>
						<div v-else class="bonus-table__line-flex">
							<div v-for="h in expandedHeaders" :key="h.value">
								<span class="cell-title">{{ h.text }}</span>:
								<span class="bonus-table__line-flex-content">
									<slot :name="`item.${h.value}`" v-bind="{ item, value: item[h.value], header: h }">
										{{ formatItem(item[h.value], h.value) }}
									</slot>
								</span>
							</div>
						</div>
					</template>
				</div>
			</template>
		</QExpandableDataTable>

		<v-row v-if="doShowDisclaimer">
			<v-col>
				<p class="bonus-table__disclaimer">{{ disclaimer }}</p>
			</v-col>
		</v-row>
	</v-card>
</template>

<script>
import moment from 'moment'
import { Parser } from 'json2csv'

import QExpandableDataTable from '@/components/datatables/QExpandableDataTable.vue'
import QDataTableHeader from '@/components/datatables/base/QDataTableHeader.vue'

import { loadBonusReportType } from '../api/index'
import tableConfigs, { bonusTypes, bonuses, bonusTypesStatOptions } from '../config/bonusConfigs'

export default {
    components: {
        QDataTableHeader,
        QExpandableDataTable,
    },
    props: {
        bonusType: {
            type: String,
            default: '',
        },
		filters: {
			type: Object,
			required: true,
		},
		agent: { type: Object },
		expandedTable: {
			type: Boolean,
			default: false,
		},
    },
    data () {
        return {
			search: null,
            expanded: [],
			options: {
				sortBy: [],
				sortDesc: [],
				itemsPerPage: 25,
			},
			tableKey: 0,
			dataLoadingStatus: {
				loading: false,
				error: false,
			},
			items: [],
			cachedItems: {},
			disclaimer: 'All bonus programs are based on net placed and are discretionary and may be amended, modified or terminated, in whole or in part, at any time at Symmetry Financial Group’s sole discretion. All bonuses and bonus payments are contingent on the recipient being in Good Standing (as defined in the Agent Handbook) when the bonus is earned and on the date the payment is to be made to receive the bonus.',
        }
    },
	created () {
		this.updateOptions()
        this.refreshData()
	},
    computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		isTabletView () {
			return this.$vuetify.breakpoint.smAndDown && !this.isMobileView
		},
		doShowDisclaimer () {
			return this.bonusType !== bonusTypes.pcs && this.bonusType !== bonusTypes.qrsFif
		},
		computedClass () {
			return [
				this.isMobileView && 'bonus-table--mobile',
				this.isTabletView && 'bonus-table--tablet',
			]
		},
		title () {
			return bonuses.find(({ type }) => type === this.bonusType)?.label
		},
		config () {
			return tableConfigs[this.bonusType] || []
		},
		headers () {
			return this.config.map(item => ({
                text: item.label,
                value: item.key,
                class: item.type === 'currency' ? 'text-end' : '',
                cellClass: item.type === 'currency' ? 'text-end' : '',
			}))
		},
		isMobileTableView () {
			return this.isMobileView || this.isTabletView
		},
		visibleHeaders () {
			return this.isMobileTableView
				? this.headers.filter(({ value }) => this.config.find(({ key }) => key === value).mobileVisible)
				: this.headers.filter(({ value }) => this.config.find(({ key }) => key === value).visible !== false)
		},
		expandedHeaders () {
			return this.headers.filter(({ value }) => !this.visibleHeaders.some(h => h.value === value))
		},
		showExpand () {
			return this.expandedHeaders.length !== 0
		},
		totalBonus () {
			const key = this.config.find(({ summable }) => summable).key
			return this.items.reduce((acc, item) => {
				acc += Number(item[key])
				return acc
			}, 0)
		},
        exportFields () {
			const headers = this.config.map(({ key }) => key)
            return ['ID', ...headers]
        },
		computedFilters () {
			const { period, label, startDateFormat, endDateFormat, statType } = this.filters
			let startDate = startDateFormat
			let endDate = endDateFormat
			if (label.startsWith('Current')) {
				startDate = period === 'month'
					? moment().startOf('month').format('YYYY-MM-DD')
					: moment().startOf('week').format('YYYY-MM-DD')
				endDate = period === 'month'
					? moment().endOf('month').format('YYYY-MM-DD')
					: moment().endOf('week').format('YYYY-MM-DD')
			}
			return { period, label, startDate, endDate, statType }
		},
		formattedDatePeriod () {
			const { label, startDate, endDate } = this.computedFilters
			if (!startDate || !endDate) { return this.slugify(label) }
			const snake = `${this.formatDate(startDate)}_${this.formatDate(endDate)}`
			const kebab = `${moment(startDate).format('MM/DD/YY')} - ${moment(endDate).format('MM/DD/YY')}`
			return { snake, kebab }
		},
		formattedStatType () {
			const bonusTypesWithOptions = bonusTypesStatOptions.map(({ type }) => type)
			const isOptionsAvailableBonus = bonusTypesWithOptions.includes(this.bonusType)
			return isOptionsAvailableBonus
				? `_${this.filters.statType}`
				: ''
		},
		agentCode () {
			return this.agent ? this.agent.AgentCode : this.user.Agent.AgentCode
		},
    },
    methods: {
        async loadData () {
			const filters = {
				startDate: this.computedFilters.startDate,
				endDate: this.computedFilters.endDate,
				statType: this.computedFilters.statType,
			}
			const cacheKey = `${this.agentCode}_${filters.startDate}_${filters.endDate}_${filters.statType}`
			// check cache
			if (this.cachedItems[cacheKey]) {
				this.items = [...this.cachedItems[cacheKey]]
				this.setDataLoadingStatus(false, false)
				return Promise.resolve()
			}
			// call API
			this.setDataLoadingStatus(true, false)
			this.items = []
			const res = await loadBonusReportType(this.bonusType, this.agentCode, filters)
			if (res.error) {
				this.setDataLoadingStatus(false, true)
				this.showError(`Oops! There was a problem loading ${this.title} Bonus data.`, res.message)
			} else {
				this.cachedItems[cacheKey] = res.data
				this.items = [...this.cachedItems[cacheKey]]
				this.setDataLoadingStatus(false, false)
			}
        },
		setDataLoadingStatus(loading, error) {
			this.dataLoadingStatus.loading = loading
			this.dataLoadingStatus.error = error
			this.$emit('data-loading', { ...this.dataLoadingStatus })
		},
        refreshData () {
            this.expanded = []
            this.loadData()
        },
        exportResults () {
			if (!this.items?.length) { return }
            const opts = { fields: this.exportFields }
            try {
                const parser = new Parser(opts)
                const csv = parser.parse(this.items)
                const fileURL = window.URL.createObjectURL(new Blob([csv]))
                const fileLink = document.createElement('a')
                fileLink.href = fileURL
				const agentName = this.agent?.AgentName ? `${this.agent.AgentName}_` : ''
                const fileName = this.slugify(
					agentName + `${this.title}_Bonus_` + this.formattedDatePeriod.snake + this.formattedStatType
				)
                fileLink.setAttribute('download', fileName + '.csv')
                document.body.appendChild(fileLink)
                fileLink.click()
                this.logActivity(`Quility ${this.title} Bonus Datatable Export`, {})
            } catch (err) {
				this.showError('Oops! There was a problem exporting results.', err)
            }
        },
		formatItem (value, headerKey) {
			const config = this.config.find(({ key }) => key === headerKey)
			const valueSign = value < 0 ? '-' : ''
			if (config.type === 'currency') {
				const format = config.rounded ? '($0,0)' : '($0,0.00)'
				return valueSign + this.formatNumber(Math.abs(value), format)
			}
			if (config.type === 'percent') {
				const format = config.rounded ? '(0)' : '(0.00)'
				return valueSign + this.formatNumber(Math.abs(value), format) + '%'
			}
			if (config.type === 'date') {
				return config.format && config.toFormat
					? moment(value, config.format).format(config.toFormat)
					: value
			}
			if (value === undefined || value === null) {
				return '-'
			}
			return value
		},
		updateOptions () {
			const item = this.config.find(({ sortBy }) => sortBy)
			this.options = {
				...this.options,
				sortBy: item?.key ? [item.key] : [],
                sortDesc: item?.sortDesc ? [item.sortDesc] : [false],
			}
		},
    },
    watch: {
		isMobileTableView () {
			this.tableKey += 1
		},
		computedFilters () {
			this.refreshData()
		},
		agentCode () {
			this.refreshData()
		},
    },
}
</script>

<style lang="scss" scoped>
@import '../style/style.scss';

$block: bonus-table;
$header-height: 4.5rem;

.#{$block} {
	--width-search: 25rem;

	background-color: $color-white;

	&--tablet,
	&--mobile {
		--width-search: unset;

		.#{$block}__table ::v-deep {
			thead tr th {
				height: 32px;
				text-wrap: wrap;
			}

			tr th,
			tr td {
				padding: 0 0.5rem;
			}

			.v-data-footer {
				padding: 0;

				.v-data-footer__select {
					margin-right: 2rem;

					.v-select {
						margin-left: 1rem;
					}
				}

				.v-data-footer__pagination {
					margin: 0 1rem 0 0;
				}

				.v-btn {
					margin: 0;
				}
			}
		}
	}

	&__header {
		background-color: $color-table-header !important;
		min-height: $header-height;
		display: flex;
		width: 100%;
		align-items: center;

		&-summary {
			margin-bottom: 0;
			font-size: 1.75em;
			font-weight: 700;
			line-height: 1.3;
		}
	}

	&__header-search {
		margin-top: 0;
		margin-left: auto;
		max-width: var(--width-search);
	}

	&__table {
		margin-top: 1rem;
		background-color: $color-white;

		::v-deep {
			thead tr th {
				// text-wrap: nowrap;
				vertical-align: text-bottom;
			}

			tbody tr:nth-of-type(odd) {
				background-color: $color-bg;
			}

			.v-data-table__expanded.v-data-table__expanded__content {
				box-shadow: none;

				.q-expand-table__expand-td {
					background-color: $color-white !important;
					padding: 0.25rem !important;
	
					table tbody tr:last-of-type td {
						border-bottom: none;
					}

					table tr {
						background-color: transparent !important;
					}
				}
			}
		}
	}

	&__expand-wrapper {
		padding: 0.5rem;
		border-radius: 0.25rem;
		color: #00000099;
		box-shadow: 0px 1px 2px 2px #eeeeee;
	}

	&__line-table {
		table {
			th, td {
				height: 24px !important;
			}
	   	}
	}

	&__line-flex {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		row-gap: 0.5rem;
		column-gap: 3rem;

		&-content {
			margin-left: 0.5rem;
			white-space: nowrap;
		}
	}

	::v-deep .cell-title {
		font-weight: 600;
	}

	&__disclaimer {
		margin: 1rem 0 0;
		font-size: 1rem;
		font-weight: 600;
	}
}
</style>
