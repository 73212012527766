import api from '../../../store/API/QuilityAPI'

export type Filters = {
  startDate: string,
  endDate: string,
  statType: string,
}

type BonusTypes = 'PCS' | 'QRS' | 'Sherpa' | 'BirdDog' | 'Capital' | 'Producer' | 'Slingshot' | 'Equity'

type ErrorResponse = {
	error: boolean,
	message?: string,
}

type BonusTotalsEntry = {
	BonusType: BonusTypes,
	TotalAmount: number,
	hasBonusData: boolean,
}

type BonusTotalsResponse = {
	data: BonusTotalsEntry[]
}

type BonusResponse = {
	data: Record<string, string | number | null>[],
	hasBonusData: boolean,
}

export const loadBonusReportType = async (type: string, agentCode: string, filters: Filters) => {
	const params = { ...filters }
	return api.getRequest(`/api/bonus/${type}/${agentCode}`, params)
	    .then((response) => {
			return response as BonusResponse
		})
		.catch((err) => {
			const message = err.status === 404 ? 'Page not found.' : err.message
	        return { error: true, message } as ErrorResponse
	    })
}

export const loadBonusTotals = async (agentCode: string, filters: Partial<Filters>) => {
	const params = { ...filters }
	return api.getRequest(`/api/bonus/bonus_totals/${agentCode}`, params)
	    .then((response) => {
			return response as BonusTotalsResponse
		})
		.catch((err) => {
			const message = err.status === 404 ? 'Page not found.' : err.message
	        return { error: true, message } as ErrorResponse
	    })
}
