<template>
    <div>
        <QBusinessTimePeriodSelect
			v-model="datePeriod"
			:label="`Time Period for ${bonusLabel}`"
			:dont-reset="false"
			:allow-current="true"
			:hide-time-period-switch="true"
			:hide-all-option="true"
			earliest-date="2022-12-30"
			:disabled="disabled"
			color="#1383EA"
			calendar-dates
		/>
        <v-radio-group v-if="isOptionsAvailable" v-model="statType" :disabled="disabled" hide-details>
            <v-radio
				v-for="option in bonusOptions" :key="option.value"
				:value="option.value"
				:label="option.label"
				color="#1383EA"
			/>
        </v-radio-group>
    </div>
</template>

<script>
import QBusinessTimePeriodSelect from '@/components/utils/QBusinessTimePeriodSelect.vue'

import { optionTypes, bonusTypesStatOptions, bonuses } from '../config/bonusConfigs'

export default {
    components: {
        QBusinessTimePeriodSelect,
    },
    props: {
		value: {
			type: Object,
			required: true,
		},
		bonusType: {
			type: String,
			default: '',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
    data () {
        return {
            datePeriod: { ...this.value },
            statType: optionTypes.personal.value,
        }
    },
    computed: {
		filters () {
			return { ...this.datePeriod, statType: this.statType }
		},
		isOptionsAvailable () {
			const bonusTypesWithOptions = bonusTypesStatOptions.map(({ type }) => type)
			const isAvailableBonus = bonusTypesWithOptions.includes(this.bonusType)
			const hasRole = this.hasRole(['AgencyOwner', 'SuperAdmin', 'Exec', 'Staff'])
			return isAvailableBonus && hasRole
		},
		bonusOptions () {
			if (!this.isOptionsAvailable) { return [] }
			return bonusTypesStatOptions
				.find(({ type }) => type === this.bonusType).options
				.map(options => ({ ...options }))
		},
		bonusLabel () {
			return bonuses.find(({ type }) => type === this.bonusType)?.label || this.bonusType
		},
    },
    watch: {
		filters () {
			this.$emit('input', this.filters)
		},
    },
}
</script>
