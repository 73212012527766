<template>
	<v-row>
		<v-col cols="12">
			<v-card class="elevation-0">
				<QDataTableHeader color="q_leads_1" id="lead_datatable">
					<template #left>
						<div id="q-pending-policy-step-3">
							<p class="q-display-3 d-flex align-center white--text my-1">
								{{ title }}
								<v-btn class="ml-2" dark icon @click="$emit('refresh-links')">
									<v-icon>fas fa-redo-alt</v-icon>
								</v-btn>
							</p>
						</div>
					</template>
					<template #right>
						<v-text-field v-model="search" class="mt-0" dense dark prepend-icon="icon-q-search" label="Search" single-line hide-details clearable />
					</template>
				</QDataTableHeader>

				<v-data-table
					:items="items"
					:headers="headers"
					:search="search"
					item-key="IDX"
					:loading="agentCalendarLinks.loading || (loading && !computedValue)"
					:footer-props="{ itemsPerPageOptions: [10,25,50,100] }"
					:mobile-breakpoint="0"
					class="elevation-0"
				>
					<template #item.Delete="{ item }">
						<v-btn color="error" small fab icon :disabled="loading" @click.stop="$emit('remove-link', item.Agent, item.CalendarURL)">
							<v-icon>fas fa-times-circle</v-icon>
						</v-btn>
					</template>
					<template #item.Edit="{ item }">
						<v-btn color="warning" small fab icon :disabled="loading" @click.stop="showEditLinkDialog(item)">
							<v-icon>fas fa-pen</v-icon>
						</v-btn>
					</template>
				</v-data-table>
			</v-card>
		</v-col>

		<v-dialog v-model="computedValue" width="100%" max-width="1600px">
			<v-card v-if="computedValue" class="flex" :loading="loading">
				<v-card-title>
					<p style="font-size:1rem;font-weight:600" v-html="dialogTitle" />
				</v-card-title>
				<v-card-text>
					<div v-if="linkDescription" class="pb-4" v-html="linkDescription" />
					<QUrlInput
						v-model="editCalendarURLForm.calendarLink" 
						:isValid.sync="editCalendarURLForm.isCalendarLinkValid"
						:error-messages="duplicateLinkErrorMessage"
						placeholder="Calendar link"
						class="mt-0 pt-0"
					/>
				</v-card-text>
				<v-card-actions class="flex justify-center pb-4">
					<v-btn @click="computedValue = false">Cancel</v-btn>
					<v-btn
						color="primary"
						:disabled="isSaveButtonDisabled"
						@click="$emit('update-link', editCalendarURLForm.calendarLink, editCalendarURLForm.linkData.Agent)"
					>
						Save
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-row>
</template>

<script>
import QUrlInput from '@/components/utils/QUrlInput.vue'
import QDataTableHeader from '../datatables/base/QDataTableHeader.vue'

export default {
    name: "QAgentCalendarLinkTable",
    components: {
        QDataTableHeader,
        QUrlInput,
    },
	props: {
		value: { type: Boolean, default: false },
		agentCalendarLinks: { type: Object, required: true },
		certifiedAgents: { type: Object, required: true },
		title: String,
		pool: String,
		calendarUrlKey: String,
		loading: Boolean,
	},
    data () {
        return {
            search: '',
            editCalendarURLForm: {
                linkData: null,
                calendarLink: '',
                isCalendarLinkValid: true,
            },
        }
    },
    computed: {
		computedValue: {
			get () { return this.value },
			set (v) { this.$emit('input', v) },
		},
        items () {
            const items = this.agentCalendarLinks.items ?? []
			return items.map((item, idx) => ({ ...item, IDX: idx })) // workaround due to existence of duplicates
        },
        headers () {
            return [
                {
                    text: 'Agent Name',
                    value: 'Agent.AgentName',
                    divider: true,
                },
                {
                    text: 'Calendar URL',
                    value: 'CalendarURL',
                    divider: true,
                },
				{
					text: 'Edit',
					value: 'Edit',
					align: 'center',
					divider: true,
					sortable: false,
					filterable: false,
					width: '1%',
				},
				{
					text: 'Delete',
					value: 'Delete',
					align: 'center',
					sortable: false,
					filterable: false,
					width: '1%',
				},
            ]
        },
		duplicateLinkErrorMessage () {
			if (!this.computedValue || !this.items.length || !this.editCalendarURLForm.calendarLink) { return '' }
			const { calendarLink, linkData } = this.editCalendarURLForm
			const duplicate = this.items.find(item => linkData.Agent?.AgentCode !== item.Agent.AgentCode && calendarLink === item.CalendarURL)
			if (!duplicate) { return '' }
			const { AgentCode, AgentName } = duplicate.Agent
			return `This calendar link is already in the ${this.pool} reset pool (at agent ${AgentName} - ${AgentCode}).`
		},
        dialogTitle () {
            if (!this.computedValue || !this.editCalendarURLForm.linkData) { return '' }
            const agentName = this.editCalendarURLForm.linkData.Agent?.AgentName
            if (!agentName) { return '' }
            return `Editing the Calendar URL for agent <strong>${agentName}</strong>`
        },
		isSaveButtonDisabled () {
			if (!this.computedValue || !this.editCalendarURLForm.linkData) { return false }
			return !this.editCalendarURLForm.isCalendarLinkValid ||
				!!this.duplicateLinkErrorMessage ||
				this.loading ||
				this.editCalendarURLForm.calendarLink === this.editCalendarURLForm.linkData.CalendarURL
		},
		linkDescription () {
			if (!this.computedValue || !this.certifiedAgents.items || !this.editCalendarURLForm.linkData) { return '' }
			const agent = this.certifiedAgents.items.find(agent => agent.AgentCode === this.editCalendarURLForm.linkData.Agent.AgentCode)
			if (agent && agent[this.calendarUrlKey] && agent[this.calendarUrlKey] !== this.editCalendarURLForm.linkData.CalendarURL) {
				return `<p>The ${agent.AgentName}'s calendar link in your ${this.pool} reset pool is different from the ${this.pool} Calendar URL (<strong>${agent[this.calendarUrlKey]}</strong>) in agent config.</p>`
			}
			return ''
		},
    },
    methods: {
        showEditLinkDialog (item) {
            this.editCalendarURLForm.linkData = item
            this.editCalendarURLForm.calendarLink = item.CalendarURL
            this.computedValue = true
        },
    },
}
</script>

<style scoped>
.nowrap {
    white-space: nowrap;
}
</style>
