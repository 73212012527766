<template>
    <div class="bonus-scorecards" :class="computedClass">
		<v-row justify="space-between" class="flex-wrap-reverse">
			<v-col cols="auto" class="bonus-scorecards__summary">
				<span class="bonus-scorecards__summary-dates">{{ formattedDatePeriod }}</span>
				<div class="bonus-scorecards__summary-row">
					<span class="bonus-scorecards__summary-label mr-4">Bonus Total</span>
					<v-progress-circular v-if="loading" size="24" color="#1383EA" class="align-self-center" indeterminate />
					<v-btn
						v-else-if="error"
						class="error-btn"
						elevation="0"
						color="#1383EA"
						@click="$emit('reload')"
					>
						<v-icon>fas fa-sync-alt</v-icon>
						<v-divider class="mx-2" color="white" vertical />
						Reload Bonus Totals
					</v-btn>
					<span v-else class="bonus-scorecards__summary-value">
						<span v-if="bonusTotal < 0">-</span>
						<span class="with-currency">{{ formatNumber(Math.abs(bonusTotal), '(0,0)') }}</span>
					</span>
				</div>
			</v-col>
			<v-col cols="auto" class="flex-grow-1">
		        <QBusinessTimePeriodSelect
					:value="value"
					label="Time Period for Bonus Total"
					:dont-reset="false"
					:allow-current="true"
					:hide-time-period-switch="true"
					:hide-all-option="true"
					earliest-date="2022-12-30"
					:disabled="loading"
					class="bonus-scorecards__time-selector"
					color="#1383EA"
					calendar-dates
					@input="$emit('input', $event)"
				/>
			</v-col>
		</v-row>

		<v-row class="mt-4">
			<v-col cols="12">
				<BonusCards
					:items="bonusCards"
					:current-bonus-type="currentBonusType"
					:loading="loading"
					:error="error"
				/>
			</v-col>
		</v-row>
    </div>
</template>

<script>
import moment from 'moment'

import QBusinessTimePeriodSelect from '@/components/utils/QBusinessTimePeriodSelect.vue'

import BonusCards from './BonusCards.vue'
import { bonuses } from '../config/bonusConfigs'

export default {
    components: {
		BonusCards,
		QBusinessTimePeriodSelect,
	},
	props: {
		value: {
			type: Object,
			required: true,
		},
		items: {
			type: Array,
			default: () => ([]),
		},
		currentBonusType: {
			type: String,
			default: '',
		},
		loading: {
			type: Boolean,
			default: false,
		},
		error: {
			type: Boolean,
			default: false,
		},
	},
    computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		isTabletView () {
			return this.$vuetify.breakpoint.mdAndDown && !this.isMobileView
		},
		computedClass () {
			return [
				this.isMobileView && 'bonus-scorecards--mobile',
				this.isTabletView && 'bonus-scorecards--tablet',
			]
		},
		computedDatePeriod () {
			const { period, label, startDateFormat, endDateFormat } = this.value
			let startDate = startDateFormat
			let endDate = endDateFormat
			if (label.startsWith('Current')) {
				startDate = period === 'month'
					? moment().startOf('month').format('YYYY-MM-DD')
					: moment().startOf('week').format('YYYY-MM-DD')
				endDate = period === 'month'
					? moment().endOf('month').format('YYYY-MM-DD')
					: moment().endOf('week').format('YYYY-MM-DD')
			}
			return { period, label, startDate, endDate }
		},
		formattedDatePeriod () {
			const { label, startDate, endDate } = this.computedDatePeriod
			if (!startDate || !endDate) { return `For ${label}` }
			const formattedStartDate = moment(startDate).format('MM/DD/YY')
			const formattedEndDate = moment(endDate).format('MM/DD/YY')
			return `${formattedStartDate} - ${formattedEndDate}`
		},
		bonusCards () {
			return bonuses.map(({ type, label }) => {
				const bonus = this.items?.find(({ BonusType }) => BonusType === type)
				return { type, label, value : bonus?.TotalAmount, hasBonusData: bonus?.hasBonusData }
			})
		},
		bonusTotal () {
			return this.bonusCards
				.filter(({ value }) => value)
				.reduce((acc, bonus) => {
					acc += Number(bonus.value)
					return acc
				}, 0)
		},
    },
}
</script>

<style lang="scss" scoped>
@import '../style/style.scss';

$block: bonus-scorecards;

.#{$block} {
	--font-summary-dates: 1.25rem;
	--font-summary-value: 2.25rem;
	--width-time-selector: 14rem;

	&--tablet {
		--font-summary-dates: 1.25rem;
		--font-summary-value: 2.25rem;
	}

	&--mobile {
		--font-summary-dates: 1.25rem;
		--font-summary-value: 2.25rem;
	}

	&__summary {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;

		&-dates {
			font-size: var(--font-summary-dates);
		}

		&-row {
			display: flex;
			flex-wrap: wrap;
			gap: 1rem;
		}

		&-value,
		&-label {
			font-size: var(--font-summary-value);
			line-height: 1;
		}

		&-value {
			font-weight: bold;
		}
	}

	&__time-selector {
		margin: 0 0 0.5rem auto !important;
		max-width: var(--width-time-selector);

		::v-deep .v-input.v-select {
			margin: 0 !important;
		}
	}
}
</style>
