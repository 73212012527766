<template>
	<v-container fluid class="bonus-report" :class="computedClass">
		<BonusScorecards
			v-model="datePeriod"
			:items="items"
			:current-bonus-type="currentBonusType"
			:loading="dataLoading"
			:error="dataError"
			@reload="loadData"
		/>
		<BonusTables
			v-model="currentBonusType"
			:agent="agent"
			:has-bonus-data="{
				availableBonuses: bonusTypesDataAvailable,
				loading: dataLoading,
				error: dataError,
			}"
		/>
	</v-container>
</template>

<script>
import moment from 'moment'

import BonusScorecards from '../components/BonusScorecards.vue'
import BonusTables from '../components/BonusTables.vue'
import { loadBonusTotals } from '../api/index'
import { bonuses } from '../config/bonusConfigs'

export default {
    components: {
		BonusScorecards,
		BonusTables,
	},
	props: {
		agent: { type: Object },
	},
    data () {
        return {
			currentBonusType: bonuses[0].type,

			datePeriod: {
				startDateFormat: null,
				endDateFormat: null,
				period: 'month',
				label: 'Current Month',
			},

			items: null,
			cachedItems: {},
			dataLoading: false,
			dataError: false,

			bonusTypesDataAvailable: null,
        }
    },
	created () {
		this.loadData()
		this.getCurrentBonusTypeFromRouteParams()
	},
	activated () {
		this.getCurrentBonusTypeFromRouteParams()
	},
	deactivated () {
		this.removeCurrentBonusTypeFromRouteParams()
	},
    computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		isTabletView () {
			return this.$vuetify.breakpoint.mdAndDown && !this.isMobileView
		},
		computedClass () {
			return [
				this.isMobileView && 'bonus-report--mobile',
				this.isTabletView && 'bonus-report--tablet',
			]
		},
		computedDatePeriod () {
			const { period, label, startDateFormat, endDateFormat } = this.datePeriod
			let startDate = startDateFormat
			let endDate = endDateFormat
			if (label.startsWith('Current')) {
				startDate = period === 'month'
					? moment().startOf('month').format('YYYY-MM-DD')
					: moment().startOf('week').format('YYYY-MM-DD')
				endDate = period === 'month'
					? moment().endOf('month').format('YYYY-MM-DD')
					: moment().endOf('week').format('YYYY-MM-DD')
			}
			return { period, label, startDate, endDate }
		},
		agentCode () {
			return this.agent ? this.agent.AgentCode : this.user.Agent.AgentCode
		},
		doShowBonusesForAllAgents () {
			return this.hasRole(['SuperAdmin', 'Staff']) && !this.agent
		},
    },
	watch: {
		computedDatePeriod () {
			this.loadData()
		},
		agentCode () {
			this.loadData()
		},
		items (newValue, oldValue) {
			if (newValue && oldValue === null) {
				this.updateBonusTypesDataAvailable()
			}
		},
	},
	methods: {
		async loadData () {
			const filters = {
				startDate: this.computedDatePeriod.startDate,
				endDate: this.computedDatePeriod.endDate,
			}
			const cacheKey = `${this.agentCode}_${filters.startDate}_${filters.endDate}`
			// check cache
			if (this.cachedItems[cacheKey]) {
				this.items = [...this.cachedItems[cacheKey]]
				this.setDataLoadingStatus(false, false)
				return Promise.resolve()
			}
			// call API
			this.setDataLoadingStatus(true, false)
			const res = await loadBonusTotals(this.agentCode, filters)
			if (res.error) {
				this.items = null
				this.setDataLoadingStatus(false, true)
				this.showError(`Oops! There was a problem loading Bonus Totals data.`, res.message)
			} else {
				this.cachedItems[cacheKey] = res.data
				this.items = [...this.cachedItems[cacheKey]]
				this.setDataLoadingStatus(false, false)
			}
		},
		setDataLoadingStatus(loading, error) {
			this.dataLoading = loading
			this.dataError = error
		},
		updateBonusTypesDataAvailable () {
			this.bonusTypesDataAvailable = bonuses.reduce((acc, { type }) => {
				const bonusTotal = this.items?.find(({ BonusType }) => BonusType === type)
				acc[type] = this.doShowBonusesForAllAgents || (bonusTotal ? bonusTotal.hasBonusData : false)
				return acc
			}, {})
		},
		getCurrentBonusTypeFromRouteParams () {
			if (this.$route.params.bonusType) {
				this.currentBonusType = this.$route.params.bonusType
			}
		},
		removeCurrentBonusTypeFromRouteParams () {
			if (this.$route.params.bonusType) {
				delete this.$route.params.bonusType
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../style/style.scss';

$block: bonus-report;

.#{$block} {
	--block-padding: 1rem 2rem;

	&--tablet {
		--block-padding: 1rem 1.5rem;
	}

	&--mobile {
		--block-padding: 0.5rem 1rem;
	}

	position: relative;
	height: 100%;
	padding: var(--block-padding);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	color: $color-black;
	background-color: $color-bg;
}
</style>
