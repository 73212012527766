<template>
	<QTabs
		v-model="currentTab"
		dark grow
		height="76" slider-size="10"
		slider-color="#54C1B2" background-color="#125751"
		class="bonus-tables" :class="computedClass" menu-content-class="bonus-tables__menu-content"
	>
		<template v-for="bonus in bonuses">
			<v-tab :key="`${bonus.type}_tab`">{{ bonus.label }}</v-tab>

			<v-tab-item :key="`${bonus.type}_tab-item`">
				<v-row v-if="hasBonusData.availableBonuses?.[bonus.type] === false" justify="center">
					<v-col cols="auto">
						<div
							v-html="bonusDefinitions[bonus.type]"
							class="bonus-tables__bonus-definition"
						/>
					</v-col>
				</v-row>

				<v-row
					v-else-if="hasBonusData.availableBonuses?.[bonus.type] === true"
					class="flex-nowrap"
					:class="isMobileTableView && 'flex-wrap-reverse'"
				>
					<div class="bonus-tables__col-left">
						<BonusReportTable
							:bonus-type="bonus.type"
							:filters="filters[bonus.type]"
							:agent="agent"
							@data-loading="dataLoadingStatus[bonus.type] = $event"
						>
							<template v-if="bonus.type === bonusTypes.sherpa" #item.Sherpa_AgencyProducingAgentName="{ item, value }">
								<v-btn v-if="item.Sherpa_AgencyProducingAgentCode" text :to="`/agents/${item.Sherpa_AgencyProducingAgentCode}`" class="bonus-tables__sherpa-btn">{{ value || 'go to agent details' }}</v-btn>
								<span v-else>{{ value || 'NA' }}</span>
							</template>
						</BonusReportTable>
					</div>
					<v-col cols="12" md="auto">
						<div class="bonus-tables__side" :class="isMobileTableView && 'd-flex flex-column align-end'">
							<BonusReportFilters
								v-model="filters[bonus.type]"
								:bonus-type="bonus.type"
								:disabled="dataLoadingStatus[bonus.type].loading"
								class="bonus-tables__time-selector"
							/>
							<v-btn
								class="bonus-tables__dispute-btn"
								text
								elevation="0"
								color="#1383EA"
								@click="submitForm(bonus.type)"
							>
								Submit a Dispute Form
							</v-btn>
						</div>
					</v-col>
				</v-row>

				<v-progress-linear v-else class="mt-1" indeterminate />
			</v-tab-item>
		</template>

		<v-dialog
			v-model="doShowForm"
			:fullscreen="$vuetify.breakpoint.xs"
			width="100%"
			max-width="800px"
			@click:outside="closeAndClearForm"
			@keydown.esc="closeAndClearForm"
		>
			<v-card>
				<v-card-title>Dispute Form Data</v-card-title>
				<FormRenderer
					:slug="formSlug"
					class="px-4 pb-4"
					@saved="onFormSubmit"
				/>
			</v-card>
		</v-dialog>
	</QTabs>
</template>

<script>
import QuilityAPI from '@/store/API/QuilityAPI'
import Page from '@/store/Models/Page'
import QTabs from '@/components/navigation/QTabs.vue'

import FormRenderer from '@/modules/FML/components/FormRenderer.vue'
import BonusReportFilters from './BonusReportFilters.vue'
import BonusReportTable from './BonusReportTable.vue'
import { bonuses, optionTypes, bonusTypes } from '../config/bonusConfigs'

const getDefaultFilters = () => bonuses.reduce((acc, { type }) => {
	acc[type] = {
		startDateFormat: null,
		endDateFormat: null,
		period: 'month',
		label: 'Current Month',
		statType: optionTypes.personal.value,
	}
	return acc
}, {})

const getDefaultDataLoadingStatus = () => bonuses.reduce((acc, { type }) => {
	acc[type] = { loading: false, error: false }
	return acc
}, {})

export default {
    components: {
		QTabs,
		BonusReportFilters,
		BonusReportTable,
		FormRenderer,
	},
	props: {
		value: {
			type: String,
			default: '',
		},
		hasBonusData: {
			type: Object,
			default: () => ({}),
		},
		agent: { type: Object },
	},
    data () {
        return {
			bonuses,
			bonusTypes,
            filters: getDefaultFilters(),
			dataLoadingStatus: getDefaultDataLoadingStatus(),

			cmsPageSlug: 'bonus-report-definitions',
			cmsPageLoading: false,
			cmsPageError: false,
			cmsPageBlocks: null,

			formSlug: undefined,
			doShowForm: false,
        }
    },
	created () {
		this.loadCmsPage()
	},
    computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		isTabletView () {
			return this.$vuetify.breakpoint.mdAndDown && !this.isMobileView
		},
		isMobileTableView () {
			return this.isMobileView || this.isTabletView
		},
		computedClass () {
			return [
				this.isMobileView && 'bonus-tables--mobile',
				this.isTabletView && 'bonus-tables--tablet',
				!this.hasBonusData.availableBonuses && 'bonus-tables--progress',
			]
		},
		currentTab: {
			get () {
				return this.bonuses.findIndex(({ type }) => type === this.value)
			},
			set (val) {
				const type = this.bonuses[val].type
				this.$emit('input', type)
			},
		},
		bonusDefinitions () {
			if (!this.cmsPageBlocks) { return {} }
			return this.bonuses.reduce((acc, { type, label }) => {
				acc[type] = this.cmsPageBlocks.find(({ Title }) => {
					const title = Title.trim?.().toLowerCase()
					return title === type.toLowerCase() || title === label.toLowerCase()
				})?.text
				return acc
			}, {})
		},
    },
	watch: {
		currentTab () {
			this.closeAndClearForm()
		},
	},
	methods: {
		async loadCmsPage () {
			const page = Page.query().where('slug', this.cmsPageSlug).first()
			if (page) {
				this.cmsPageBlocks = page.blocks
				return Promise.resolve()
			}
			this.cmsPageLoading = true
			this.cmsPageError = false
			try {
				await QuilityAPI.getPageWithSlug(this.cmsPageSlug)
				const page = Page.query().where('slug', this.cmsPageSlug).first()
				this.cmsPageBlocks = page.blocks
			} catch (err) {
				this.showError('Oops! There was a problem loading the cms page with bonus definitions.', err.message)
				this.cmsPageError = true
			} finally {
				this.cmsPageLoading = false
			}
		},
		submitForm (bonusType) {
			this.formSlug = this.getFormSlug(bonusType)
			this.doShowForm = true
		},
		onFormSubmit () {
			this.showSuccess('Dispute Form submitted.')
			this.closeAndClearForm()
		},
		closeAndClearForm () {
			this.doShowForm = false
			this.formSlug = undefined
		},
		getFormSlug (bonusType) {
			switch (bonusType) {
				case bonusTypes.sherpa:
					return 'sherpa-bonus-dispute-form'
				case bonusTypes.birdDog:
					return 'bird-dog-bonus-dispute-form'
				case bonusTypes.pcs:
					return 'commission-dispute-form'
				case bonusTypes.qrsFif:
					return 'commission-dispute-form'
				default:
					return 'bonus-dispute-form'
			}
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../style/style.scss';

$block: bonus-tables;

.#{$block} {
	--font-tab: 1.5rem;
	--width-time-selector: 14rem;

	&--tablet {
		--font-tab: 1.5rem;
	}

	&--mobile {
		--font-tab: 1.5rem;
	}

	&:not(.bonus-tables--progress) {
		::v-deep .v-tabs-items {
			padding: 0 12px;
			background-color: $color-white;
			border: 1px solid $color-card-border;
			border-top: none;
			border-bottom-left-radius: $border-radius;
			border-bottom-right-radius: $border-radius;
		}
	}

	margin-top: 3rem;

	::v-deep .q-tabs__select {
		.v-select__selection {
			margin: 0 0.5rem 0 0;
			font-size: var(--font-tab);
			line-height: 1;
		}
	}

	::v-deep .v-tabs {
		.v-tab {
			font-size: var(--font-tab);
			color: $color-white !important;
			text-transform: unset;
		}
		.v-tab--active {
			background-color: $color-tabs-active;
		}
	}

	&__col-left {
		flex-basis: 0;
		flex-grow: 1;
		flex-shrink: 1;
		max-width: 100%;
		width: 100%;
		padding: 12px;
		overflow-x: auto;
	}

	&__col-right {
		flex: 0 0 auto;
		width: auto;
		max-width: 100%;
		padding: 12px;
	}

	&__side {
		padding: 0.5rem;
		height: 100%;
		background-color: $color-bg;
	}

	&__time-selector {
		max-width: var(--width-time-selector);

		::v-deep .q-business-time-period-select {
			margin: 0 !important;

			.v-input.v-select {
				margin: 0 !important;
			}
		}
	}

	&__bonus-definition {
		padding-top: 1rem;
		max-width: 1200px;
		overflow: hidden;
	}

	&__dispute-btn {
		margin-top: 1rem;
		text-transform: none;
		text-decoration: underline;
	}

	&__sherpa-btn {
		padding: 0 0.5rem !important;
		font-size: 14px;
		text-transform: none;
		letter-spacing: normal;
	}
}
</style>

<style lang="scss">
@import '../style/style.scss';

.bonus-tables__menu-content {
	.v-list {
		background-color: $color-tabs !important;

		.v-list-item--active {
			color: inherit !important;
		}
	}
}
</style>
