<template>
    <v-container fluid class="pa-0 pa-md-3">
        <v-card class="elevation-0">
			<QDataTableHeader color="q_leads_1" :id=" entity + '_datatable'">
				<template #left>
					<div class="white--text d-flex align-center">
                        <p class="q-display-3 mb-0" id="datatable">{{ title }}</p>
                        <v-btn class="ml-1" dark icon @click="refresh">
                            <v-icon>fas fa-redo-alt</v-icon>
                        </v-btn>
                    </div>
				</template>

				<template #right>
					<v-spacer></v-spacer>
                    <v-text-field dense dark v-model="search" append-icon="icon-q-search" label="Search" single-line hide-details @keyup="maybeSearch" @click:append="refreshData" />
				</template>
			</QDataTableHeader>

			<div class="data-table-actions">
				<v-btn text small color="q_leads_4" @click="toggleFilters">
					<v-icon small left>fas fa-filter</v-icon> Filters
				</v-btn>
				<v-btn :disabled="loading || !all_data.length" text small color="q_leads_4" @click="downloadCsvData">
					<v-icon small left>fas fa-file-export</v-icon> Export Results
				</v-btn>
			</div>

            <div class="data-table-container__row">
				<div class="data-table-container__col data-table-col">
					<v-data-table v-on:current-items="updateCurrentItems" :mobile-breakpoint="2" :items-per-page.sync="rows" v-model="selected" :headers="the_headers" :items="all_data" item-key="$id" class="elevation-0 pointer" :footer-props="{ itemsPerPageOptions: [10, 25, 50, 100] }" :loading="loading" :options.sync="options" :server-items-length="total_items" v-on:click:row="viewApplication">
						<template v-slot:item.CreateDate="{ item }">
							<span class="nowrap">{{ formatDate(item.CreateDate) }}</span>
						</template>
						<template v-slot:item.AgentName="{ item }">
							<router-link v-if="item.AgentCode" class="nowrap q_leads_4--text" :to="'/agents/' + item.AgentCode" @click.native.stop>{{ item.AgentName }}</router-link>
							<span v-else class="nowrap">{{ item.AgentName }}</span>
						</template>
						<template v-slot:item.FirstName="{ item }">
							<span class="nowrap">{{ item.FirstName }} {{ item.LastName }}</span>
						</template>
						<template v-slot:item.FaceAmount="{ item }">
							<span class="nowrap">{{ formatCurrency(item.FaceAmount) }}</span>
						</template>
						<template v-slot:item.APV="{ item }">
							<span class="nowrap">{{ formatCurrency(item.APV) }}</span>
						</template>
						<template v-slot:item.SplitPercent="{ item }">
							<v-icon small v-if="item.SplitPercent > 0">fas fa-check</v-icon>
						</template>
						<template v-slot:body.append>
							<tr>
								<th class="title">Totals</th>
								<th colspan="4"></th>
								<th class="title text-right">{{ sumField('APV') }}</th>
							</tr>
						</template>
					</v-data-table>
				</div>

				<div v-show="showFilters" class="data-table-container__col filters-col">
                    <QFilters
						ref="filters"
						v-model="filters"
						:available-filters="available_filters"
						:keys="filter_keys"
						dark hide-button column
					>
						<template #before>
							<v-col cols="12">
								<QDatePeriodFilter v-model="datePeriod" />
							</v-col>
						</template>
						<v-col cols="12">
							<QPolicyTableStatFilter
								v-model="statType"
							/>
						</v-col>
					</QFilters>
				</div>
			</div>
        </v-card>

        <v-dialog v-model="view_application_details" width="500px" max-width="90%" persistent>
            <v-card class="pa-6" min-height="" v-if="current_application">
                <v-progress-linear color="q_leads_1" indeterminate v-if="saving"></v-progress-linear>
                <v-row wrap>
                    <v-col cols="12">
                        <h2>{{ current_application.FirstName }} {{ current_application.LastName }}</h2>
                        <div class="mb-3" v-for="item in application_details_data" :key="item.title">
                            <span v-if="current_application[item.title]">
                                <strong>{{ item.title }}</strong>: {{ current_application[item.title] }}<br />
                            </span>
                        </div>
                        <v-progress-linear color="q_leads_1" indeterminate v-if="status_items == null"></v-progress-linear>
                        <div v-else>
                            <v-row class="d-flex px-5">
                                <v-spacer></v-spacer>
                                <v-btn depressed @click=" view_application_details = false; saving = false;">Close</v-btn>
                            </v-row>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import moment from 'moment'
import { debounce } from 'vue-debounce'

import QuilityAPI from '@/store/API/QuilityAPI.js'
import Application from '@/store/Models/Application'
import QDataTableHeader from './base/QDataTableHeader.vue'
import QDatePeriodFilter from '@/components/utils/QDatePeriodFilter.vue'
import QPolicyTableStatFilter from '@/components/datatables/QPolicyTableStatFilter.vue'
import QDataTableMixin from './QDataTableMixin'

const statDefault = 'PersonalProduction'

const datePeriodDefault = { 
	startDate: null, // YYYY-MM-DD
	endDate: null, // YYYY-MM-DD
}

export default {
    name: 'QApplicationsTable',
    components: {
        QDataTableHeader,
		QPolicyTableStatFilter,
		QDatePeriodFilter,
    },
    mixins: [QDataTableMixin],
    props: {
		agentCode: String,
	},
    data () {
        return {
            entity: 'application',
            view_application_details: false,
            //use this to "preset" a filter
            filters: {},
            firstPull: true, // this keeps the auto scrolling on data reloads from happening on the first load.
            applications: [],
            current_application: null,
            options: {
				sortBy: ['CreateDate'],
				sortDesc: [true],
			},
            status_items: [],
            saving: false,
            orig_application_status: null,
            application_details_data: [
                { title: 'PolicyNumber' },
                { title: 'Type' },
                { title: 'Source' },
                { title: 'Product' },
                { title: 'ProductType' },
                { title: 'Carrier' },
                { title: 'FaceAmount' },
                { title: 'APV' },
                { title: 'SplitPercent' },
            ],
            datePeriod: { ...datePeriodDefault },
			statType: statDefault, // TotalAgency | Baseshop | PersonalProduction
        }
    },
    mounted: function() {
        this.loadStatuses();
    },
    computed: {
        filter_keys: function() {
            return Application.state().filter_keys;
        },
        all_data: function() {
            return this.applications;
        },
        the_headers () {
			return [
				{
					text: 'Date Created',
					value: 'CreateDate',
					align: 'start',
					sortable: true,
					filterable: true,
				},
				{
					text: 'Agent',
					value: 'AgentName',
					align: 'start',
					sortable: true,
					filterable: true
				},
				{
					text: 'Policy #',
					value: 'PolicyNumber',
					align: 'start',
					sortable: true,
					filterable: true
				},
                {
                    text: 'Client',
                    value: 'FirstName',
                    align: 'start',
                    sortable: true,
                    filterable: true
                },
                {
                    text: 'Face Amount',
                    value: 'FaceAmount',
                    align: 'right',
                    sortable: true,
                    filterable: true
                },
				{
					text: 'APV',
					value: 'APV',
                    align: 'right',
					sortable: true,
					filterable: true,
				},
				{
					text: 'Carrier',
					value: 'Carrier',
                    align: 'start',
					sortable: true,
					filterable: true,
				},
				{
					text: 'Product',
					value: 'Product',
                    align: 'start',
					sortable: true,
					filterable: true,
				},
				{
					text: 'Product Type',
					value: 'ProductType',
                    align: 'start',
					sortable: true,
					filterable: true,
				},
				{
					text: 'Type',
					value: 'Type',
                    align: 'start',
					sortable: true,
					filterable: true,
				},
				{
					text: 'Source',
					value: 'Source',
                    align: 'start',
					sortable: true,
					filterable: true,
				},
				{
					text: 'Split',
					value: 'SplitPercent',
                    align: 'start',
					sortable: false,
					filterable: false,
				},
			]
        },
		computedAgentCode () {
			return this.agentCode ? this.agentCode : this.user.Agent.AgentCode
		},
    },
    watch: {
        view_application_details: function() {
            this.current_application.Status = this.orig_application_status;
            this.logActivity("ViewApplication", { AgentCode: this.user.Agent.AgentCode });
        },
        datePeriod: {
			deep: true,
			handler () {
				this.options.page = 1
				this.refreshData()
			},
		},
		statType () {
			this.options.page = 1
			this.refreshData()
		},
		computedAgentCode () {
			this.options.page = 1
			this.refreshData()
		},
		showFilters (val) {
			if (!val) {
				this.setDateFilterDefaultValue()
				this.setStatFilterDefaultValue()
			}
		},
    },
    methods: {
        loadData: debounce(function(opt) {
            const filters = {
                ...this.filters,
				...this.datePeriod,
				statType: this.statType,
            }
            QuilityAPI.getApplications(this.computedAgentCode, filters, opt)
                .then((results) => {
                    if (typeof results.data != "undefined") {
                        this.applications = results.data;
                    } else {
                        this.showError("Whoops! " + results.message);
                    }
                    Application.commit((state) => {
                        state.loading = false;
                    });
                })
                .catch((err) => {
					if (err.message === 'Aborted') { return }
                    console.log(err)
					this.showError('Whoops! There was a problem loading Submitted Policies.<br>', err)
                })
            this.firstPull = false
        }, 200),
        viewApplication: function(application) {
            this.orig_application_status = application.Status;
            this.current_application = application;
            this.view_application_details = true;
        },
        loadStatuses: function() {
            const g = this;
            QuilityAPI.getStatusOptions()
                .then(function(response) {
                    g.status_items = response;
                })
                .catch(function(err) {});
        },
        refresh () {
            this.applications = []
            Application.commit((state) => {
                state.total = null;
                state.availableFilters = null;
            })
            this.refreshData()
        },
        downloadCsvData () {
            const params = {
                ...this.options,
                ...this.filters,
				...this.datePeriod,
                search: this.search,
				statType: this.statType,
                itemsPerPage: 'csv',
            }
            const path = `/api/private/applications/${this.computedAgentCode}`
            this.dispatchJob({
                method: 'GET',
                path,
                params,
                JobName: 'Applications Export',
            })
        },
        status_itemsHasIndexOf: function(s) {
            for (var i = 0; i < this.status_items.length; i++) {
                if (this.status_items[i].Status == s) {
                    return true;
                }
            }
            return false;
        },
        getStatus: function(s) {
            for (var i = 0; i < this.status_items.length; i++) {
                if (this.status_items[i].Status == s) {
                    return this.status_items[i];
                }
            }
            return null;
        },
        updateFilters(filters) {
            Object.keys(this.filters).forEach((key) => {
                this.filters[key] = filters[key] || "-All-";
            });

            this.goToTop();
        },
        goToTop: function() {
            this.$vuetify.goTo("#" + this.entity + "_datatable", {
                duration: 500,
                easing: "easeOutCubic",
                offset: 300,
            });
        },
        formatDate(value) {
            return moment(value).format("YYYY-MM-DD")
        },
        formatCurrency (value) {
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value)
        },
        sumField(key) {
            let totals = this.applications.reduce((a, b) => a + Number(b[key] || 0), 0)
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(totals)
        },
		setDateFilterDefaultValue () {
			this.datePeriod.startDate = datePeriodDefault.startDate
			this.datePeriod.endDate = datePeriodDefault.endDate
		},
		setStatFilterDefaultValue () {
			this.statType = statDefault
		},
    },
}
</script>

<style lang="scss" scoped>
.nowrap {
    white-space: nowrap;
}

.table-cursor tbody tr:hover {
    cursor: pointer;
}

.data-table-container__row {
	width: 100%;
	display: flex;
	flex-wrap: wrap-reverse;
	gap: 1rem 3rem;

	.data-table-container__col {
		max-width: 100%;
	}

	.data-table-col {
		flex: 10 10 600px;
		overflow-x: auto;
		padding: 12px;
	}

	.filters-col {
		flex: 1 0 280px;
	}
}

.data-table-actions {
	margin: 1rem 2rem;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	gap: 1rem 2rem;
}
</style>
